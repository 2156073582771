// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";

import { faMinus as fasFaMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { faPlus as fasFaPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faAngleRight as fasFaAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft as fasFaAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";

import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter as fabFaTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faXTwitter as fabFaXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter";
import { faYoutube as fabFaYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaMinus, fasFaPlus, fasFaTimes, fasFaAngleRight, fasFaAngleLeft);

/**
 * Add regular icons
 */
library.add(farFaBars);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaTwitter, fabFaInstagram, fabFaXTwitter, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
